/**
 * React.
 */
import React from 'react';

/**
 * Layout.
 * Used for basic page structure.
 */
import Layout from "../components/layout"

/**
 * SEO.
 * Used for page SEO content.
 */
import Seo from "../components/seo"

/**
 * Formik.
 * https://formik.org/
 * https://formik.org/docs/overview
 * 
 * Send form data to Wordpress via REST API.
 * https://developer.wordpress.org/rest-api/
 * 
 * Required Wordpress plugin.
 * - Contact Form 7
 */
import { Formik, Form, Field, ErrorMessage } from 'formik';

/**
 * Global variable(s).
 * 
 * Notes:
 * Set custom global variables for dev/prod environments.
 * 
 * Example Var:
 * GATSBY_ENVIRONMENT="Development"
 * 
 * To add/view value(s) in current file(s),
 * Development: {root}/.env.development
 * Production:  {root}/.env.production
 * 
 * To reference the variable(s) in code use,
 * let foo    = process.env.{VARIABLE-NAME1}
 * const blah = process.env.{VARIABLE-NAME2}
 */
const remoteUrl = process.env.GATSBY_REMOTE_URL

const Contact = () => (
    <Layout>
        {/* SEO details */}
        <Seo title="Contact" />    
        <div>
            <Formik
            /* Set initial values */ 
            initialValues={{ 
                email: '', 
                name: '', 
                subject: '', 
                message: '' 
            }}
            validate={values => {
                const errors = {};
                /* Set errors for field values */ 
                if (!values.email) {
                    errors.email = 'Your email is required.';
                } else if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Invalid email address.';
                }
                if (!values.name) {
                    errors.name = 'Your name is required.';
                }
                if (!values.subject) {
                    errors.subject = 'Email subject is required.';
                }   
                if (!values.message) {
                    errors.message = 'Your message is required.'; 
                }                           
                return errors;
            }}
            /* On form submit */
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    /* Set form variables */
                    let form_values = JSON.stringify( values, null, 2 )
                    let jsonObj = JSON.parse( form_values );
                    let url = remoteUrl + '/wp-json/contact-form-7/v1/contact-forms/5/feedback';
                    let params = 'your-email=' + jsonObj.email
                              + '&your-name=' + jsonObj.name
                              + '&your-subject=' + jsonObj.subject
                              + '&your-message=' + jsonObj.message;
                    /* Send the form data to Wordpress */                        
                    fetch( url, {
                        method: 'POST',
                        headers: {'Content-Type':'application/x-www-form-urlencoded'},
                        body: params
                    })
                    .then( res => res.json() )
                    .then( data => {
                        /* On response, what should we do? */
                        document.getElementById( 'form_fields' ).remove();
                        document.getElementById( 'form_submitted' ).textContent = 'Post has been sent!';
                        setSubmitting(false);
                        resetForm(); 
                    })
                    .catch(error => {
                        /* Console log error(s) */
                        console.log(error)
                    })               
                }, 400);
            }}
            >
            {({ isSubmitting }) => (
                <Form>
                    <div className="container">
                        <div className="row"> 
                            <div className="col-md-12 p-1 text-center">

                                {/* Form details */}         
                                <h1>Contact</h1>
                                <p>( WordPress &gt; Contact Form 7 )</p>

                                {/* Form response when submitted */}
                                <div id="form_submitted" className="form-submitted"></div>

                            </div> 

                            {/* Form fields */}
                            <div id="form_fields" className="col-md-12 p-1 text-center"> 

                                {/* Form field - subject */}
                                <div className="pb-1">
                                    <Field type="text" name="subject" placeholder="Subject" className="contact-field" />   
                                    <ErrorMessage name="subject" component="div" className="contact-field-error d-flex justify-content-center" />
                                </div>

                                {/* Form field - email */}           
                                <div className="pb-1">
                                    <Field type="email" name="email" placeholder="Email address" className="contact-field" />
                                    <ErrorMessage name="email" component="div" className="contact-field-error d-flex justify-content-center" />
                                </div>

                                {/* Form field - name */}
                                <div className="pb-1">
                                    <Field type="text" name="name" placeholder="Name" className="contact-field" />
                                    <ErrorMessage name="name" component="div" className="contact-field-error d-flex justify-content-center" />
                                </div>

                                {/* Form field - message */}
                                <div className="pb-3">
                                    <Field type="text" name="message" placeholder="Message" component="textarea" className="contact-field" style={{height: '200px'}}/>
                                    <ErrorMessage name="message" component="div" className="contact-field-error d-flex justify-content-center" />
                                </div>

                                {/* Form submit button */}                                        
                                <div className="d-flex justify-content-center">
                                    <button type="submit" 
                                            disabled={isSubmitting} 
                                            className="btn btn-outline-success"
                                            style={{width: '280px'}}>
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
    </Layout>
);

export default Contact;
